<template>
  <div>
    <header-portrate />

    
  <div class="m-2">
    <bread-crumbs :items='items'/>
    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row class="mt-10">
        <v-col
          cols="12"
          md="8"
          sm="12">
          <b-input-group class="mt-3">
            <b-form-input placeholder="Start Date" type="date" v-model="sdate"></b-form-input>
            <b-form-input placeholder="End Date"  type="date" v-model="edate"></b-form-input>
            <b-form-input :placeholder="lang.mobile"  v-model="mobile"></b-form-input>
            <b-form-input :placeholder="lang.plate_number" v-if="$store.state.licenseType.cars"  v-model="plate_number"></b-form-input>
            <b-form-input :placeholder="lang.invoiceno"  v-model="invoice_number"></b-form-input>
            <b-input-group-append style="border:1px solid gold">
              <b-button variant="light" style="background:gold"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="text-end">
          <b-button
              v-b-toggle.add_invoice
              class="btn-sm btn "
              style="margin-top:11px;width:150px;background:transparent;border:none"
              id="multiply-button" 
            >
            <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_invoice}}</div>
            
            </b-button>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
          style="display:none;"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="lang.search"
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        
       
      </v-row>
      <v-row>
        <v-col cols="12" :style="`direction:`+lang.dir+` ;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              width="100%"
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event" 
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:100px;text-align:center;">{{ row.item.invoice_number }}</td>
                <td style="width:100px;text-align:center;">{{ row.item._invtype }}</td>
                <td style="text-align:center;" nowrap>{{ row.item.full_name }}</td>
                <td style="text-align:center;">{{ row.item.mobile }}</td>
                <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ row.item.plate_number }}</td>
                <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts">{{ row.item.model }}</td>
                <td style="text-align:center;" nowrap>{{ row.item.inv_date.substr(0,10) }}</td>  
                <td style="text-align:center;">{{ row.item.total }}</td>
                <td style="text-align:center;">{{ row.item.vat }}</td>
                <td style="text-align:center;">{{ row.item.ftotal }}</td>
                <td style="text-align:center;">{{ row.item.paid }}</td>
                <td style="text-align:center;">{{ row.item.remain }}</td>
                <td style="text-align:center;" v-if="$store.state.group_id == 1 && $store.state.licenseType.showAddedBy">{{ row.item._created_by }}</td>
                <td :style='`width:100px;border-bottom:2px solid #FFF;text-align:center;color:#FFF !important;background:#`+row.item._statusbgColor'>{{ row.item._status }}</td>
                <td style="text-align:center;background:#000;border-bottom:2px solid #FFF !important;width:50px;">
                  <v-btn style="font-size:0.2em;background:#000;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn>
                  <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                </td>
              </tr>
            </template>
            <template slot="body.append">
                    <tr class="black--text">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo"></th>
                        <th v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo"></th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{lang.totals}}</th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ sumField('total') }}</th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ sumField('vat') }}</th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ sumField('ftotal') }}</th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ sumField('paid') }}</th>
                        <th style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ sumField('remain') }}</th>
                        <th></th>
                        <th></th>
                    </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <view-invoice :invoice="edinvoice" v-if="viewInvoice" />
    <addInvoice />
    <vue-snotify></vue-snotify>
  </div>
  <popViewInvocie ref="popviewinv" />
  <Footer />
  </div>
</template>

<script>
  import axios from 'axios'
  import addInvoice from '@/components/addInvoice.vue'
import ViewInvoice from '@/components/viewInvoice.vue'
import {SnotifyPosition} from 'vue-snotify';
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import PopViewInvocie from '@/components/popViewInvocie.vue';
  export default{
    name: 'Invoicelist',
    components: {
        addInvoice,
        ViewInvoice,
        BreadCrumbs,
        Footer,
        HeaderPortrate,
        PopViewInvocie
    },
    data() {
      return {
        plate_number: '',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        edinvoice: 0,
        
        sdate: new Date().toISOString().substring(0, 10),
        edate: new Date().toISOString().substring(0, 10),
        mobile: '',
        excel_fields:{},
        tablerows: [
          
        ],
        ishome: true,
        viewInvoice: false,
        invoice_number: '',
      }
    },
    computed: {
      lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      headers: function () {
        let x =  [
            { 
              text: this.lang.invoiceno, align: 'center',
              filterable: false, value: 'invoice_number',
              sortable: false,
            },
            {
              text: this.lang.invtype, align: 'center',
              filterable: false, value: 'invtype',
              sortable: false,
            },
            {
              text: this.lang.customer, align: 'center',
              filterable: false, value: 'full_name',
              sortable: false,
            },
            {
              text: this.lang.mobile, align: 'center',
              filterable: true, value: 'mobile',
              sortable: false, 
            },
        ];
        if(this.$store.state.licenseType.cars || this.$store.state.licenseType.carinfo){
          x.push({
              text: this.lang.plate, align: 'center',
              filterable: true, value: 'plate_number',
              sortable: false,
            });
          x.push({
              text: this.lang.model, align: 'center',
              filterable: false, value: 'model',
              sortable: false,
              });
        }
        else if(this.$store.state.licenseType.parts){
          x.push({
              text: this.lang.model, align: 'center',
              filterable: false, value: 'model',
              sortable: false,
              });
        }


          x.push({
            
                text: this.lang.inv_date, align: 'center',
                filterable: false, value: 'inv_date',
                sortable: false,
              })
              ,
          x.push({
              text: this.lang.total, align: 'center',
              filterable: false, value: 'total',
              sortable: false,
            })
          x.push({
              text: this.lang.vat, align: 'center',
              filterable: false, value: 'vat',
              sortable: false,
            });

          x.push({
              text: this.lang.ftotal, align: 'center',
              filterable: false, value: 'ftotal',
              sortable: false,
            })
          x.push({
              text: this.lang.paid, align: 'center',
              filterable: true, value: 'paid',
              sortable: false,
            })
          x.push({
              text: this.lang.remain, align: 'center',
              filterable: true, value: 'remain',
              sortable: false,
            })
          if(this.$store.state.group_id == 1 && this.$store.state.licenseType.showAddedBy){
            x.push({
              text: this.lang.added_by, align: 'center', filterable: true,
              value: '_status', sortable: false,      
            });
          }
          x.push({
              text: this.lang.status, align: 'center', filterable: true,
              value: '_status', sortable: false,
            })
          x.push({
              text: this.lang.action, align: 'center',
              filterable: false, value: '',
              sortable: false,
            });
        
        return x;
      }
    },
    created() {
      this.getCurrentCards();
      // setInterval(() => {this.getCurrentCards();},3000);
      this.getExcelHead();
    },
    methods: {
    cancelInvocie(item)
      {
          const invID = item.id;
          this.$snotify.confirm('هل أنت متاكد بانك تريد الغاء هذه الفاتورة؟ لا يمكن التراجع عن هذا االاجراء', 'تحذير', {
              timeout: 100000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              position: SnotifyPosition.centerCenter,
              buttons: [
                  {
                      text: 'متأكد', 
                      action: (toast) => {
                          this.doCancel(invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'اغلاق', 
                      action: (toast) => {
                          this.$snotify.remove(toast.id); 
                      } 
                  },
              ]
          })
          if(!this.doitrelay){
              return false;
          }
      },
      doCancel(invID){
        if (this.$snotify.notifications.length > 0) {
            this.$snotify.notifications.forEach(notification => {
                this.$snotify.remove(notification.id)
            });
        }
        this.$snotify.error('يرجى اختيار سبب الالغاء'  , {
              timeout: 1000000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              
              position: SnotifyPosition.centerCenter,
              buttons: [
                  {
                      text: 'خطأ في الادخال', 
                      action: (toast) => {
                          this.doCancelIt(1,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'سوء الخدمة', 
                      action: (toast) => {
                          this.doCancelIt(2,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'السعر غير مناسب ', 
                      action: (toast) => {
                          this.doCancelIt(3,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'الغاء الامر', 
                      action: (toast) => {
                          this.$snotify.remove(toast.id); 
                      } 
                  },
              ]
          })
      },
      doCancelIt(id,invID){
            const post = new FormData();
            let cansel_reason = 'خطا في الادخال';
            if(id == 2){
              cansel_reason = 'سوء الخدمة';
            }
            else if(id == 3){
              cansel_reason = 'السعر غير مناسب';
            }
            post.append('type', 'cancelInvo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',invID)
            post.append('data[cancel_reson]',cansel_reason)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.success('تم ارجاع الفاتورة بنجاح'  , {
                  timeout: 4000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  position: SnotifyPosition.centerCenter,
                });
                this.getCurrentCards();
            })
      },
      getInvo(item){
          this.$refs.popviewinv.invid = item.id;
          this.$refs.popviewinv.invoice = item;
          this.$refs.popviewinv.getInvoice();
          // this.$router.push({path: '/view-invoice/'+item.id})
      },
      revokit(item)
      {
          if (this.$snotify.notifications.length > 0) {
              this.$snotify.notifications.forEach(notification => {
                  this.$snotify.remove(notification.id)
              });
          }
          this.$snotify.error('هل أنت متأكد بانك تريد الغاء الفاتورة؟', 'تحذير', {
                timeout: 6000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: 'متأكد', 
                        action: (toast) => {
                            this.cancelConfirmed();
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            })
      },
      printIt(item){
          window.open('../api/print.php?invid='+item.id,"_blank");
      },
      getExcelHead(){
        const head = this.headers;
        
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        //   console.log("xx",this.excel_fields[x]);
        // }
        
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {
        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        if(x != 0) return x;
        else return 0;
      },
      ViewItem(item){
        
        if(item.cardid == null || typeof item.cardid === 'undefined'){
          return false;
        }else{
          this.$router.push({ path: '/view-card/'+item.cardid });
        }
      },
      getCurrentCards(){
        
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        let post = new FormData();
        post.append('type','getAllInvoices');
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        post.append('auth',cook);
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
          invoice_number: this.invoice_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',data.plate_number);
        post.append('data[invoice_number]',data.invoice_number);
        this.tablerows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          // console.log(res);
          if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
              && res.error.number != null && res.error.number == 200){
                this.tablerows = res.results.data;
              }
        })
      },
      ExportToExcel(){
        //
      },
    }
  }
</script>
<style>

</style>
